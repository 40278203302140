import { AxiosResponse } from "axios"

//types
import {
  createEntityRepository,
  createProfileRepository,
  createRepositoryInstance,
  createUtilsRepository,
} from "ftl-dashboards-core"
import { Position, PositionRequest } from "../types/entities/position"
import { PositionCategory, PositionCategoryRequest } from "../types/entities/positionCategory"
import { Department, DepartmentRequest } from "../types/entities/department"
import {
  PositionAvailableOnDepartment,
  PositionAvailableOnDepartmentRequest,
} from "../types/entities/positionAvailableOnDepartment"

enum Versions {
  v1 = "v1",
  v2 = "v2",
}

// export const baseUrl =
//   process.env.REACT_APP_ENV === "dev"
//     ? `http://localhost:9090/api/${Versions.v1}`
//     : `http://localhost:9090/api/${Versions.v1}`
//
// export const apiBaseUrl =
//   process.env.REACT_APP_ENV === "dev"
//     ? "http://localhost:9102"
//     : "http://localhost:9102"
//
// export const repository = createRepositoryInstance({
//   baseUrl,
//   tokenRefreshUrl:
//     "http://localhost:9102/not-secure/api/v1/auth/refresh",
//   userBlockedPageUrl: "/user-blocked",
// })

export const baseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? `https://api.hr-saas.foodtech-lab.ru/api/${Versions.v1}`
    : `https://api.hr-saas.foodtech-lab.ru/api/${Versions.v1}`

export const apiBaseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? "https://auth.hr-saas.foodtech-lab.ru"
    : "https://auth.hr-saas.foodtech-lab.ru"


export const repository = createRepositoryInstance({
  baseUrl,
  tokenRefreshUrl:
    "https://auth.hr-saas.foodtech-lab.ru/not-secure/api/v1/auth/refresh",
  userBlockedPageUrl: "/user-blocked",
})

export const UtilsAPI = createUtilsRepository(repository)

//Profile

export const ProfileAPI = createProfileRepository(repository)

//Picture

export const PictureAPI = Object.freeze({
  post: (request: FormData): Promise<AxiosResponse<{ result: string }>> => {
    return repository.post(`pictures`, request)
  },
})


// Positions

const normalizePositionPayload = (payload: Position): Position => ({
  // activeStatus: payload.activeStatus,
  title: payload.title,
  categoryId: payload.categoryId,
  hardSkills: payload.hardSkills,
  responsibilities: payload.responsibilities,
  kpi: payload.kpi,
  otherRequirements: payload.otherRequirements,
  salaryFrom: payload.salaryFrom,
  salaryUpTo: payload.salaryUpTo,
  currency: payload.currency,
  salaryCondition: payload.salaryCondition,
  createdAt: payload.createdAt,
})

export const PositionAPI = {
  ...createEntityRepository<any, Position<"GET">, Position, Position<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizePositionPayload,
    path: "positions",
    utils: UtilsAPI,
  }),
  getList: (params: PositionRequest) => {
    return UtilsAPI.getList("/positions", params)
  },
  delete: (id: Position["id"]) => {
    return repository.delete(`/positions/` + id)
  },
}

// PositionCategories

const normalizePositionCategoryPayload = (
  payload: PositionCategory,
): PositionCategory => ({
  title: payload.title,
  parentId: payload.parentId,
  description: payload.description,
  // activeStatus: payload.activeStatus,
})

export const PositionCategoryAPI = {
  ...createEntityRepository<any, PositionCategory<"GET">, PositionCategory, PositionCategory<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizePositionCategoryPayload,
    path: "position-categories",
    utils: UtilsAPI,
  }),
  getList: (params: PositionCategoryRequest) => {
    return UtilsAPI.getList("/position-categories", params)
  },
  delete: (id: PositionCategory["id"]) => {
    return repository.delete(`/position-categories/` + id)
  },

}

// Departments

const normalizeDepartmentPayload = (
  payload: Department,
): Department => ({
  shortName: payload.shortName,
  parentDeps: payload.parentDeps,
  leaderIds: payload.leaderIds,
  description: payload.description,
  address: payload.address,
  fullName: payload.fullName,
  createdAt: payload.createdAt,
})


export const DepartmentAPI = {
  ...createEntityRepository<any, Department<"GET">, Department, Department<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeDepartmentPayload,
    path: "departments",
    utils: UtilsAPI,
  }),
  getList: (params: DepartmentRequest) => {
    return UtilsAPI.getList("/departments", params)
  },
  delete: (id: Department["id"]) => {
    return repository.delete(`/departments/` + id)
  },

}

// Position Available On Department

const normalizePositionAvailableOnDepartmentPayload = (
  payload: PositionAvailableOnDepartment,
): PositionAvailableOnDepartment => ({
  departmentId: payload.departmentId,
  positionId: payload.positionId,
  salaryFrom: payload.salaryFrom,
  salaryUpTo: payload.salaryUpTo,
  currency: payload.currency,
  salaryCondition: payload.salaryCondition,
})


export const PositionAvailableOnDepartmentAPI = {
  ...createEntityRepository<any,
    PositionAvailableOnDepartment<"GET">,
    PositionAvailableOnDepartment,
    PositionAvailableOnDepartment<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizePositionAvailableOnDepartmentPayload,
    path: "positions-available-on-department",
    utils: UtilsAPI,
  }),
  getList: (params: PositionAvailableOnDepartmentRequest) => {
    return UtilsAPI.getList("/positions-available-on-department", params)
  },
  delete: (id: PositionAvailableOnDepartment["id"]) => {
    return repository.delete(`/positions-available-on-department/` + id)
  },
}

