import { AuthProvider } from "ftl-auth-module"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core"
import { theme } from "ftl-dashboards-ui-kit"
import { Router } from "react-router-dom"

import { Routes } from "./Routes"

import { apiBaseUrl, ProfileAPI } from "./repository"
import { basicReducer, history, initialState, StoreProvider } from "ftl-dashboards-core"
import { createStore } from "redux"

const store = createStore(
  basicReducer,
  initialState,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

export const UIMain = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <CssBaseline />
        <AuthProvider
          baseUrl={`${apiBaseUrl}/not-secure/api/v1/auth`}
          endpoints={{
            signIn: "/login/password",
            signOut: "/logout",
            refresh: "/refresh",
          }}
        >
          <StoreProvider store={store} getProfile={ProfileAPI.get}>
            <Routes />
          </StoreProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  )
}

export default UIMain
