import ReactDOM from "react-dom"
import App from "./App"
import { registerSW } from "ftl-dashboards-core"

// Sentry.init({
//   dsn:
//     process.env.NODE_ENV === "development"
//       ? "https://cd80dabe51294b80a6c8cb94bce14394@o582149.ingest.sentry.io/5736394"
//       : "https://cd80dabe51294b80a6c8cb94bce14394@o582149.ingest.sentry.io/5736394",
//   autoSessionTracking: true,
//   integrations: [new Integrations.BrowserTracing()],
//   environment: process.env.NODE_ENV,
//   tracesSampleRate: 1.0,
// })


ReactDOM.render(<App />, document.getElementById("root"))

registerSW("/service-worker.js")
