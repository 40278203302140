import React, { lazy, Suspense } from "react"
import { Route, Switch } from "react-router-dom"
import { Box } from "@material-ui/core"
//components
import { FTLAuthPage, FTLBaseLayout } from "ftl-dashboards-templates"

import {
  ErrorContainer,
  FTLErrorBoundary,
  FTLErrorPage,
  FTLLoader,
  OrganizationIcon,
  PeopleIcon,
} from "ftl-dashboards-ui-kit"

import { FolderIcon } from "ftl-dashboards-ui-kit/dist/components/icons"
import { PrivateRoute } from "ftl-auth-module"
import PositionAvailableOnDepartmentPage from "./pages/PositionAvailableOnDepartment/List"
import DetailPositionAvailableOnDepartmentPage from "./pages/PositionAvailableOnDepartment/Detail"

const PositionsPage = lazy(() => import("./pages/Positions/List"))
const PositionCategoryPage = lazy(
  () => import("./pages/PositionCategories/List"),
)
const DetailPositionPage = lazy(() => import("./pages/Positions/Detail"))
const DetailPositionCategoryPage = lazy(
  () => import("./pages/PositionCategories/Detail"),
)
const DepartmentsPage = lazy(() => import("./pages/Departments/List"))
const DetailDepartmentPage = lazy(() => import("./pages/Departments/Detail"))

//Profile
// const ProfilePage = lazy(
//   () => import("ftl-dashboards-templates/dist/templates/FTLProfilePage")
// )

export const Routes = () => {
  // const dispatch = useDispatch()
  return (
    <Switch>
      <Route exact path="/">
        <FTLAuthPage
          redirectOnAuthUrl="/positions"
          title="Войти в HR SaaS Admin"
        />
      </Route>
      <Route>
        <FTLBaseLayout
          title="HR SaaS Admin"
          // onSignOut={() => dispatch(logOutAction)}
          items={[
            {
              icon: <FolderIcon />,
              label: "Должности",
              to: "/positions",
            },
            {
              icon: <PeopleIcon />,
              label: "Сотрудники",
              to: "/positions",
            },
            {
              icon: <OrganizationIcon />,
              label: "Департаменты",
              to: "/departments",
            },
            {
              icon: <OrganizationIcon />,
              label: "Категории",
              to: "/position-categories",
            },
          ]}
        >
          <FTLErrorBoundary>
            <ErrorContainer>
              <Suspense fallback={<FTLLoader height="50%" color="dark" />}>
                <Switch>
                  {/*<PrivateRoute exact path="/profile">*/}
                  {/*  <ProfilePage*/}
                  {/*    getProfile={ProfileAPI.get}*/}
                  {/*    editProfile={ProfileAPI.edit}*/}
                  {/*    getDefaultCountry={() => UtilsAPI.getDefault("countries")}*/}
                  {/*    deleteProfilePicture={ProfileAPI.deletePicture}*/}
                  {/*    changeProfilePassword={ProfileAPI.changePassword}*/}
                  {/*    postPicture={PictureAPI.post}*/}
                  {/*  />*/}
                  {/*</PrivateRoute>*/}


                  {/*PositionAvailableOnDepartment*/}
                  <PrivateRoute
                    exact
                    path="/positions-available-on-department/"
                  >
                    <PositionAvailableOnDepartmentPage />
                  </PrivateRoute>
                  <PrivateRoute exact path="/departments/detail/positions-available-on-department/new">
                    <Box
                      mr={15}
                      ml={15}
                      display="flex"
                      flexDirection="column"
                      style={{
                        height: "100%",
                      }}
                    >
                      <DetailPositionAvailableOnDepartmentPage pageType="new" />
                    </Box>
                  </PrivateRoute>
                  <PrivateRoute exact path="/positions-available-on-department/:id">
                    <Box
                      mr={15}
                      ml={15}
                      display="flex"
                      flexDirection="column"
                      style={{
                        height: "100%",
                      }}
                    >
                      <DetailPositionAvailableOnDepartmentPage pageType="edit" />
                    </Box>
                  </PrivateRoute>

                  {/*Position*/}

                  <PrivateRoute exact path="/positions/">
                    <PositionsPage />
                  </PrivateRoute>
                  <PrivateRoute exact path="/positions/new">
                    <Box
                      mr={15}
                      ml={15}
                      display="flex"
                      flexDirection="column"
                      style={{
                        height: "100%",
                      }}
                    >
                      <DetailPositionPage pageType="new" />
                    </Box>
                  </PrivateRoute>
                  <PrivateRoute exact path="/positions/:id">
                    <Box
                      mr={15}
                      ml={15}
                      display="flex"
                      flexDirection="column"
                      style={{
                        height: "100%",
                      }}
                    >
                      <DetailPositionPage pageType="edit" />
                    </Box>
                  </PrivateRoute>

                  {/*PositionCategories*/}

                  <PrivateRoute exact path="/position-categories/">
                    <PositionCategoryPage />
                  </PrivateRoute>
                  <PrivateRoute exact path="/position-categories/new">
                    <Box
                      mr={15}
                      ml={15}
                      display="flex"
                      flexDirection="column"
                      style={{
                        height: "100%",
                      }}
                    >
                      <DetailPositionCategoryPage pageType="new" />
                    </Box>
                  </PrivateRoute>
                  <PrivateRoute exact path="/position-categories/:id">
                    <Box
                      mr={15}
                      ml={15}
                      display="flex"
                      flexDirection="column"
                      style={{
                        height: "100%",
                      }}
                    >
                      <DetailPositionCategoryPage pageType="edit" />
                    </Box>
                  </PrivateRoute>

                  {/*// Departments*/}

                  <PrivateRoute exact path="/departments/:parentDeps?">
                    <DepartmentsPage />
                  </PrivateRoute>

                  <PrivateRoute exact path="/departments/detail/new">
                    <DetailDepartmentPage pageType="new" />
                  </PrivateRoute>
                  <PrivateRoute exact path="/departments/detail/:id">
                    <DetailDepartmentPage pageType="edit" />
                  </PrivateRoute>

                  <PrivateRoute path={["*", "/404"]}>
                    <FTLErrorPage
                      title="Страницы не существует"
                      message="Наверное, вы перешли по неправильной ссылке."
                    />
                  </PrivateRoute>


                </Switch>
              </Suspense>
            </ErrorContainer>
          </FTLErrorBoundary>
        </FTLBaseLayout>
      </Route>
    </Switch>
  )
}
