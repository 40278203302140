import { Dictionary, handleError, SelectOption } from "ftl-dashboards-core"

import { FetchAllArgs, NormalizeAllArgs } from "../../../types/entities"
import { DepartmentAPI } from "../../../repository"
import { Department } from "../../../types/entities/department"

export const normalizeDepartments = (
  { items, setItems }: NormalizeAllArgs<Department>,
  parentDeps: string,
) => {
  const normalizedLabels: Dictionary<string> = {}
  const normalizedOptions: SelectOption<string>[] = []
  items.forEach((item: Department) => {
    if (item.fullName && item.id) {
      if (!parentDeps || parentDeps === item.parentDeps) {
        normalizedLabels[item.id] = item.fullName
        normalizedOptions.push({
          value: item.id,
          label: item.fullName,
        })
      }
    }
  })
  setItems && setItems({ options: normalizedOptions, labels: normalizedLabels })
  return normalizedLabels
}

export const fetchAllDepartments = async (
  {
    allItems,
    items,
    setItems,
    setAll,
    setIsFetching,
    labelProp,
    normalize = normalizeDepartments,
  }: Omit<FetchAllArgs<Department>, "items" | "normalize" | "setItems"> &
    Partial<Pick<FetchAllArgs<Department>, "items" | "setItems">> & {
    allItems?: Department[]
    setAll?: React.Dispatch<React.SetStateAction<Department[]>>
    normalize?: (
      { items, setItems }: NormalizeAllArgs<Department>,
      departmentId: string,
    ) => Dictionary<string>
  },
  departmentId: string = "",
): Promise<Dictionary<string>> => {
  if (!allItems?.length && !items)
    try {
      const responseItems = (await DepartmentAPI.getList({ allItems: true })).data.result.items
      setAll && setAll(responseItems)
      return normalize({ items: responseItems, setItems }, departmentId)
    } catch (error) {
      handleError({
        error,
        defaultError: "Произошла ошибка при получении данных департаментов",
        setIsFetching,
      })
      return error
    }
  else
    return Promise.resolve(
      items
        ? items.labels
        : normalize({ items: allItems!, setItems, labelProp }, departmentId),
    )
}